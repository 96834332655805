import { ref } from 'vue'

export const iptData = ref([
  { 
    type: 'select',
    placeholder: '运输状态',
    value: null,
    prop: 'transportStatus',
    width: '10%',
    opt:[
      { label: '已装车', value: 2 },
      { label: '已发运', value: 3 },
      { label: '已完成', value: 4 },
    ]
  },
  {
    type: 'address',     
    placeholder: '运输线路起',
    value: null,
    prop: 'startAddress',
    width: '10%',
    changeOn:true
  },
  {
    type: 'address',
    placeholder: '运输线路终',
    value: null,
    prop: 'endAddress',  
    width: '10%',
    changeOn:true
  },
  {
    type: 'selectTime',
    placeholder: '派车时间起',
    value: null,
    prop: 'departureTimeStart',
    width: '10%',
  },
  {
    type: 'selectTime',
    placeholder: '派车时间止',
    value: null,
    prop: 'departureTimeEnd',
    width: '10%',
  },
  { 
    type: 'input',
    placeholder: '客户名称',
    value: null,
    prop: 'carrierName',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '大车车牌号',
    value: null,
    prop: 'transportNo',
    width: '10%',
  },
  { 
    type: 'input',
    placeholder: '小车车牌号',
    value: null,
    prop: 'vehicleNo',
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '司机姓名',
    value: null,
    prop: 'driverName',
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '派车人',
    value: null,
    prop: 'dispatcher',
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '卸车人',
    value: null,
    prop: 'unloadingOperator',
    width: '10%'
  },
  { 
    type: 'select',
    placeholder: '是否取消',
    value: 0,
    prop: 'isCancel',
    width: '10%',
    opt:[
      { label: '是', value: 1 },
      { label: '否', value: 0 },
    ]
  },
 
])
export const columns =ref([
  {   
    title: '序号',
    dataIndex: 'idx',
    slots: { customRender: 'idx' },
    align: 'center',
  },
  {
    title: '大车车牌号',
    dataIndex: 'carNo',
    slots:{ customRender: 'carNo' },
    align: 'center',
  },
  {
    title: '运输状态',
    dataIndex: 'transportStatus',
    align: 'center',
    slots:{customRender:'transportStatus'}
  },
  {
    title: '起运地-目的地',
    dataIndex: 'startAddress',
    slots:{ customRender: 'startAddress' },
    align: 'center',
  },
  {
    title: '客户',
    dataIndex: 'carrierName',
    align: 'center',
  },
  {
    title: '小车车牌号',
    dataIndex: 'vinNos',
    slots:{ customRender: 'vinNos' },
    width: '300px',
    align: 'center',
  },
  { 
    title: '司机信息',
    dataIndex: 'driverName',
    slots:{ customRender: 'driverName' },
    align: 'center',
  },
  {
    title: '派车人',
    dataIndex: 'dispatcher',
    slots:{ customRender: 'dispatcher' },
    align: 'center',
  },
  {
    title: '已占',
    dataIndex: 'usedParkPlace',
    align: 'center',
  },
  {
    title: '大车总运费',
    dataIndex: 'freightCost',
    align: 'center',
  },
  {
    title: '当前位置', 
    dataIndex: 'latestLocation',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
  },
])