<template>
<div>
  <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </ly-searchList>
    <div class="m-t1 tableBox">
    <ly-table :columns="columns" ref="tableRef" size="small" :data="data"  :pagination="pagination" @pageChange="pageChange" :loading="loading">
      <template #idx="{ index }">
        {{  index+1 }}
      </template>
      <template #vinNos="{ record }">
        <div style="width:300px">{{ record.vinNos }}</div>
      </template>
      <template #driverName="{ record }">
        {{ record.driverName }} 
        <div >{{ record.driverMobile }}</div>
      </template>
      <template #transportStatus=" { record }">
       <span :style="{color:colorList[record.transportStatus?.value]}">{{ record.transportStatus?.label }}</span>
      </template>
      <template #dispatcher="{ record }">
        {{ record.dispatcher }}
        <div>{{ record.createTime }}</div>
      </template>
      <template #startAddress="{ record }">
           {{ record.startAddress }}
           <div>{{ record.endAddress }}</div>
      </template>
      <template #add="{ record }">
        <a @click="findIn(record)">查询归档</a>
      </template>
    </ly-table>
  </div>
      <!-- 查询归档 -->
  <a-drawer title="查看归档" placement="right" width="90%" v-model:visible="searchFileShow" :destroyOnClose="true">
    <Files :transTruckId="transTruckId" />
  </a-drawer>
     
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns } from './bigCar'
import { transportAllPage } from '@/api/transport/vehicle'
import Files from './file.vue'
import { cloneDeep } from 'lodash-es';
import { transitionTimeYMD , addressTran } from '@/utils/util'


const driverVisible = ref(false)
const  msgInfo = ref({})
const data = ref([])
const loading = ref(false)
const transTruckId = ref('')
const searchFileShow = ref(false)
const searchMsg = ref({
  isCancel:0
})
const colorList = ref({
  3:'#396afc',
  4:'#099858',
  6:'#999999'
})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const changeDriver = (record) => {
  msgInfo.value = record
  driverVisible.value = true
}
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqTransportAllPage()
}

const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = cloneDeep(e) 
  searchMsg.value.startAddress = addressTran(e.startAddress)
  searchMsg.value.endAddress = addressTran(e.endAddress)
  searchMsg.value.departureTimeStart = transitionTimeYMD(e.departureTimeStart)
  searchMsg.value.departureTimeEnd = transitionTimeYMD(e.departureTimeEnd)
  reqTransportAllPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  searchMsg.value.isCancel = 0
  reqTransportAllPage()
}

const findIn = (record) => {
 transTruckId.value = record.id
 searchFileShow.value = true
}
 const reqTransportAllPage  = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
  }
  Object.assign(msg, searchMsg.value)
  transportAllPage(msg).then(res => {
    if(res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res)
  }).finally(() => {
    loading.value = false
})
 }
 onMounted(() => {
  reqTransportAllPage()
 })
</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style>